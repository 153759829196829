<script setup lang="tsx">
const themeVars = useThemeVars()
const router = useRouter()

definePageMeta({ auth: false })
</script>

<template>
  <div
    class="w-full h-dvh overflow-hidden flex relative"
    :style="{
      backgroundColor: themeVars.bodyColor,
      color: themeVars.textColorBase
    }"
  >
    <div class="absolute top-6 right-6 flex items-center gap-2">
      <!-- <LocaleSelector /> -->
    </div>

    <div class="relative flex flex-col gap-4 bg-white text-gray-800 dark:text-gray-200 w-full px-7 py-4 !lg:w-3.5/10 !lg:px-12 !lg:py-9">
      <div class="w-full flex items-center gap-4 justify-between">
        <BrandLogo />
        <LocaleSelector />
      </div>
      <NScrollbar
        class="overflow-x-hidden h-full"
        :content-style="{ height: '100%' }"
      >
        <Transition
          mode="out-in"
          :name="($route.meta.pageTransition as string) ?? 'slide-fade-reverse'"
        >
          <div
            :key="router.currentRoute.value.name"
            class="flex flex-col gap-4 h-full justify-center"
          >
            <slot />

            <template v-if="$route.meta.authLinks?.length">
              <div class="flex items-center justify-center gap-4 mt-2">
                <template
                  v-for="(link, index) in $route.meta.authLinks"
                  :key="link"
                >
                  <NuxtLink
                    v-if="link === 'register'"
                    data-testid="form:login#field:register#link"
                    to="/auth/register"
                  >
                    <NButton
                      text
                      class="text-gray-500 font-medium"
                    >
                      {{ $t("actions.register") }}
                    </NButton>
                  </NuxtLink>

                  <NuxtLink
                    v-if="link === 'login'"
                    data-testid="form:login#field:login#link"
                    to="/auth/login"
                  >
                    <NButton
                      text
                      class="text-gray-500 font-medium"
                    >
                      {{ $t("actions.login") }}
                    </NButton>
                  </NuxtLink>

                  <MarketplaceLink
                    v-if="link === 'returnSite'"
                  >
                    <NButton
                      text
                      class="text-gray-500 font-medium"
                    >
                      {{ $t("actions.returnToMarketplace") }}
                    </NButton>
                  </MarketplaceLink>

                  <NDivider
                    v-if="index !== $route.meta.authLinks.length - 1"
                    vertical
                    class="!m-0"
                  />
                </template>
              </div>
            </template>
          </div>
        </Transition>
      </NScrollbar>
    </div>

    <div class="w-0 !lg:w-6.5/10 grid place-items-center h-full" />
  </div>
</template>
